// stylelint-disable
@import "../../core/typography/typography";
@import "../../components/button/assets";

vce-token [draggable="true"] {
  isolation: isolate;
}

vce-code-editor[single-line]:not([single-line="false"]) {
  display: flex;

  vce-code-editor-toolbar {
    flex: 1 0 auto;
    order: 1;
  }

  vce-html-editor {
    overflow: auto;
  }

  .CodeMirror {
    font-family: inherit;
    background: inherit;
    color: inherit;

    pre {
      padding: 2px 0 0;
    }

    .e-label {
      margin-top: -2px;
    }
  }

  .CodeMirror-scroll {
    overflow: hidden !important;
  }

  .CodeMirror-hscrollbar {
    display: none !important;
  }

  .CodeMirror-vscrollbar {
    display: none !important;
  }

  .CodeMirror-scrollbar-filler {
    display: none !important;
  }

  .CodeMirror-activeline-background {
    background: transparent;
  }
}

vce-code-editor[disabled]:not([disabled="false"]) {
  .CodeMirror {
    background: transparent;
  }

  .CodeMirror-lines {
    color: var(--token-neutral-400);
    cursor: not-allowed;
  }

  .e-btn {
    @include e-btn-disabled;
    pointer-events: none;
  }
}

vce-html-editor {
  background-color: var(--token-background-default);
}

.code-editor-toolbar-container {
  e-tooltip {
    margin-left: $space-xs;
  }
}

vce-code-editor:not([single-line]) .code-editor-toolbar-container,
vce-code-editor[single-line="false"] .code-editor-toolbar-container {
  margin-left: -$space-xs;
  padding-bottom: 4px;
}

.CodeMirror {
  border: none;
  color: var(--token-text-default);
  font-family: var(--token-font-monospace, monospace);
  height: 100% !important;

  vce-html-editor[transparent] & {
    background: transparent;
  }

  vce-html-editor[non-monospace] & {
    font-family: inherit;
  }

  .CodeMirror-placeholder {
    color: var(--token-text-placeholder) !important;
    font-style: italic;
  }
}

/* PADDING */

.CodeMirror-lines {
  padding: 4px 0; /* Vertical padding around content */
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  background-color: var(
    --token-background-default
  ); /* The little square between H and V scrollbars */
}

/* GUTTER */

.CodeMirror-gutters {
  border-right: 1px solid var(--token-border-default);
  background-color: var(--token-background-strong);
  white-space: nowrap;
}
.CodeMirror-linenumbers {
}
.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: var(--token-text-faint);
  white-space: nowrap;
}

.CodeMirror-guttermarker {
  color: var(--token-text-default);
}
.CodeMirror-guttermarker-subtle {
  color: var(--token-text-faint);
}

/* CURSOR */

.CodeMirror-cursor {
  border-left: 1px solid var(--token-text-default);
  border-right: none;
  width: 0;
}
/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid var(--token-text-faint);
}
.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: var(--token-highlight-300);
}
.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}
.cm-fat-cursor .CodeMirror-line::selection,
.cm-fat-cursor .CodeMirror-line > span::selection,
.cm-fat-cursor .CodeMirror-line > span > span::selection {
  background: transparent;
}
.cm-fat-cursor .CodeMirror-line::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span > span::-moz-selection {
  background: transparent;
}
.cm-fat-cursor {
  caret-color: transparent;
}
@-moz-keyframes blink {
  0% {
  }
  50% {
    background-color: transparent;
  }
  100% {
  }
}
@-webkit-keyframes blink {
  0% {
  }
  50% {
    background-color: transparent;
  }
  100% {
  }
}
@keyframes blink {
  0% {
  }
  50% {
    background-color: transparent;
  }
  100% {
  }
}

/* Can style cursor different in overwrite (non-insert) mode */
.CodeMirror-overwrite .CodeMirror-cursor {
}

.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}

.CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: 0;
  overflow: hidden;
}
.CodeMirror-ruler {
  border-left: 1px solid var(--token-border-default);
  top: 0;
  bottom: 0;
  position: absolute;
}

/* DEFAULT THEME */

.cm-s-default .cm-header {
  color: var(--token-blue-500);
}
.cm-s-default .cm-quote {
  color: var(--token-green-700);
}
.cm-negative {
  color: var(--token-red-500);
}
.cm-positive {
  color: var(--token-green-500);
}
.cm-header,
.cm-strong {
  font-weight: bold;
}
.cm-em {
  font-style: italic;
}
.cm-link {
  text-decoration: underline;
}
.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: var(--token-purple-600);
}
.cm-s-default .cm-atom {
  color: var(--token-blue-700);
}
.cm-s-default .cm-number {
  color: var(--token-mint-700);
}
.cm-s-default .cm-def {
  color: var(--token-blue-500);
}
.cm-s-default .cm-variable,
.cm-s-default .cm-punctuation,
.cm-s-default .cm-property,
.cm-s-default .cm-operator {
}
.cm-s-default .cm-variable-2 {
  color: var(--token-blue-400);
}
.cm-s-default .cm-variable-3,
.cm-s-default .cm-type {
  color: var(--token-green-600);
}
.cm-s-default .cm-comment {
  color: var(--token-orange-700);
}
.cm-s-default .cm-string {
  color: var(--token-red-700);
}
.cm-s-default .cm-string-2 {
  color: var(--token-orange-600);
}
.cm-s-default .cm-meta {
  color: var(--token-gray-700);
}
.cm-s-default .cm-qualifier {
  color: var(--token-gray-700);
}
.cm-s-default .cm-builtin {
  color: var(--token-blue-700);
}
.cm-s-default .cm-bracket {
  color: var(--token-gray-500);
}
.cm-s-default .cm-tag {
  color: var(--token-mint-700);
}
.cm-s-default .cm-attribute {
  color: var(--token-blue-600);
}
.cm-s-default .cm-hr {
  color: var(--token-gray-500);
}
.cm-s-default .cm-link {
  color: var(--token-blue-600);
}

.cm-s-default .cm-error {
  color: var(--token-error-default);
}
.cm-invalidchar {
  color: var(--token-error-default);
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {
  color: var(--token-green-500);
}
div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: var(--token-pink-700);
}
.CodeMirror-matchingtag {
  background: color-mix(in srgb, var(--token-orange-500) 30%, transparent);
}
.CodeMirror-activeline-background {
  background: var(--token-background-strong);
}

/* STOP */

/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */

.CodeMirror {
  position: relative;
  overflow: hidden;
  background: var(--token-background-default);
}

.CodeMirror-scroll {
  overflow: scroll !important; /* Things will break if this is overridden */
  /* 50px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none; /* Prevent dragging from highlighting the element */
  position: relative;
  z-index: 0;
}
.CodeMirror-sizer {
  position: relative;
  border-right: 50px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
  outline: none;
}
.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}
.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}
.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3;
}
.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -50px;
}
.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}
.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
}
.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}
.CodeMirror-gutter-wrapper ::selection {
  background-color: transparent;
}
.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: transparent;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px; /* prevents collapsing before first draw */
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
}
.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px; /* Force widget margins to stay inside of the container */
}

.CodeMirror-widget {
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}
.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}
div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: var(--token-highlight-100);
}
.CodeMirror-focused .CodeMirror-selected {
  background: var(--token-highlight-200);
}
.CodeMirror-crosshair {
  cursor: crosshair;
}
.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
  background: var(--token-highlight-200);
}
.CodeMirror-line::-moz-selection,
.CodeMirror-line > span::-moz-selection,
.CodeMirror-line > span > span::-moz-selection {
  background: var(--token-highlight-200);
}

.cm-searching {
  background-color: color-mix(in srgb, var(--token-yellow-400) 40%, transparent);
}

/* Used to force a border model for a node */
.cm-force-border {
  padding-right: 0.1px;
}

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: "";
}

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none;
}
